#container{
    width:400px;
    margin:0 auto;
    margin-top:10%;
}
/* Bordered form */
.backconnect {
    width:100%;
    padding: 30px;
    border: 1px solid #f1f1f1;
    background: #fff;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
    border-radius: 5px;
}
#container h2{
    width: 38%;
    margin: 0 auto;
    padding-bottom: 10px;
}

h1{
    text-align: center;
}

/* Full-width inputs */
input[type=text], input[type=password] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 45px;
    box-sizing: border-box;
}

/* Set a style for all buttons */
.backbutton{
    background-color: #FFEE99;
    color: #222;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 45px;
    cursor: pointer;
    width: 100%;
}
input[type=submit]:hover {
    background-color: white;
    color: #53af57;
    border: 1px solid #53af57;
}