body{
    background-image: url("../resources/WheatField.jpg");
    background-size: cover;
}

.home_back{
    background-color: whitesmoke;
    background-image: none;
    min-height: 100%; 
    position: fixed;
    width: 100%;
    height: 2000px;
    max-height: 100vh;
    z-index: 10;
    overflow:auto;
}
.home_top{
    display: flex;
    justify-content: space-between;
    margin-left: 5em;
    margin-right: 5em;
}

.home_span{
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
}

.home_img{
    margin-left: 5em;
    margin-right: 5em;
    overflow: hidden; 
    height: 18em;
    background:  url("../resources/WheatField.jpg");
    background-size: cover; 
    background-attachment: fixed;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.home_img span{
    font-size: 1.2em;
    color: whitesmoke;
    text-shadow: 1px 1px 2px black;
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
}

.home_offer{
    display: flex;
    margin-top: 3em;
    margin-left: 5em;
    margin-right: 5em;
    margin-bottom: 5em;
    height: 500px;
}

.home_element{
    width: 25em;
    height: 100%;
    background:  url("../resources/WheatField.jpg");
    background-size: cover; 
    background-attachment: fixed;
    border-radius: 25px 25px 0% 0%;
}
.home_element_top{
    height: 15%;
    width: 100%;
    background-color: rgb(166, 255, 154); 
    border-radius: 25px 25px 0% 0%;
}
.home_element_under{
    height: 85%;
    width: 100%;
    background-color: rgba(221, 221, 221, 0.5);
}

.home_element_under li,h1 {
    color: white;
    text-shadow: 1px 1px 2px black;
}

.home_element_under li{
    font-size: 30px;
}

.home_void{
    display: flex;
    width: 5em;
    height: 100%;
}

